import $ from 'jquery';

import vudalKeyboard from './VudalKeyboard';

export default {
    mixins: [
        vudalKeyboard
    ],
    mounted() {
        this.startListenKeys();
        this.$eventHub.$on('focus-to-next-field', (focusedElem = null) => {
            if (this.isActiveModals() == false) {
                this.goToField(true, focusedElem);
            }
        });
        this.$eventHub.$on('focus-to-field', (field, delay = 10) => {
            this.focusToField(field, delay);
        });
    },
    beforeDestroy() {
        this.stopListenKeys();
    },
  methods: {
    startListenKeys() {
        this.$eventHub.$on('keydown', (e) => {
            // if (e.key == 'h' && e.ctrlKey == true) {
            //     this.$eventHub.$emit('show-power-modal');
            //     e.preventDefault();
            // }

            if (e.key == 'p' && e.ctrlKey == true) {
                this.$eventHub.$emit('show-power-modal');
                e.preventDefault();
            }

            if (this.isActiveModals() == false) {
              this.$eventHub.$emit('keydown-page', e);
              if (e.key == 'Tab') {
                this.goToField(true);    
                e.preventDefault();
              }
              if (e.key == 'Tab' && e.shiftKey) {
                this.goToField(false);    
                e.preventDefault();
              }
            }
        });
    },

    stopListenKeys() {
        this.$eventHub.$off('keydown');
    },

    goToField(next = true, focusedElem = null) {
        if (focusedElem == null) {
            focusedElem = document.activeElement;
        }

        const pageFields = $('.app-content-scroll').find(':input, .selectable-action').toArray();
        // console.log('pageFields', pageFields);

        if (pageFields.length > 0) {
            let currentFieldIndex = null;
            let prevFieldAfter = 0;
            let nextFieldAfter = 0;
            for (const fieldIndex in pageFields) {
                if (pageFields[fieldIndex] == focusedElem) {
                    currentFieldIndex = parseInt(fieldIndex, 10);

                    // multiselect fields as one field
                    if ($(pageFields[currentFieldIndex]).is('.multiselect')) {
                        nextFieldAfter = 1;
                    }
                    if ($(pageFields[currentFieldIndex]).is('.multiselect__input')) {
                        prevFieldAfter = 1;
                    }

                    break;
                }
            }

            // select first field
            if (currentFieldIndex == null) {
                currentFieldIndex = -1;
            }

            if (currentFieldIndex != null) {
                if (next == true) {
                    if (pageFields[currentFieldIndex + 1 + nextFieldAfter] != null) {
                        this.focusToField(pageFields[currentFieldIndex + 1 + nextFieldAfter]);
                        return;
                    } else {
                        this.focusToField(pageFields[0]);
                        return;
                    }
                } else {
                    if (pageFields[currentFieldIndex - 1 - prevFieldAfter] != null) {
                        this.focusToField(pageFields[currentFieldIndex - 1 - prevFieldAfter]);
                        return;
                    } else {
                        this.focusToField(pageFields[pageFields.length - 1]);
                        return;
                    }
                }
            }
        }
    },
    focusToField(elem, delay = 10) {
      setTimeout(() => {
            // console.log('focusToField', elem, $(elem), delay);
          $(elem).focus();
      }, delay);
    },
  },
};