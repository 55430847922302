import Vue from 'vue';
import moment from 'moment';
import * as dateFormats from '@trovan/vue-base/lib/dateFormats';

export default Vue.filter('format', (date, format) => {
  let dateFormat = format;
  if (format == null && Vue.config.lang != null) {
    dateFormat = Vue.config.lang === 'en' ? 'uk' : Vue.config.lang;
  }

  if (dateFormats[dateFormat]) {
    dateFormat = dateFormats[dateFormat];
  }
  return moment(date).format(dateFormat);
});
