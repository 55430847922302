<template>
  <div>
    <transition name="fade">
      <global-loader v-if="loading"></global-loader>
    </transition>
    <template v-if="!loading">
      <div class="wrapper">

        <module-header></module-header>

        <div class="container app-content-wrapper">
          <div class="container app-content-scroll">
            <router-view></router-view>
          </div>
        </div>
        <div class="empty"></div>
      </div>

      <module-footer></module-footer>
    </template>
    <!-- @include('layouts.partials.flash_messages') -->

    <!-- @yield('javascript') -->
  </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import { notyError, notySuccess } from '@trovan/vue-base/services/Notifications';
import fayeMixin from './mixins/Faye';
import keyboard from './mixins/Keyboard';

export default {
  name: 'RootPage',

  components: {
    ModuleHeader: () => import('./components/header'),
    ModuleFooter: () => import('./components/footer'),
    GlobalLoader: () => import('./components/globalLoader'),
  },

  mixins: [
    fayeMixin, keyboard
  ],

  mounted() {
    this.env = Vue.env;

    // this.loading = false;

    axios.get('/config/env').then(response => {
      if (response?.data != null) {
        Vue.prototype.$config = response?.data;

        this.updatePageZoom();

        if (this.$config['FAYE_URL'] != null) {
          if (this.$config['FAYE_URL'] != this.fayeUrl) {
            this.fayeUrl = this.$config['FAYE_URL'];
            this.connectToFaye();
          }
        } else {
          this.$config['FAYE_URL'] = this.fayeUrl;
        }
      }

      // this.loading = false;
      axios.get('/get_translations').then(response => {
        const locale = response?.data?.locale;
        const messages = response?.data?.data;

        if (messages != null) {
          if (locale != null) {
            this.$i18n.setLocaleMessage(locale, messages);
            this.$i18n.locale = locale;
          } else {
            this.$i18n.setLocaleMessage('en', messages);
          }
        }
        this.loading = false;
      });
    });
    this.updateContentSize();

    this.$eventHub.$on('keyboard-toggle', () => {
      this.updateContentSize();
    });

    this._keyListener = function(e) {
      this.$eventHub.$emit('keydown', e);
    };
    // fix problem to destroy the event listener
    this._keyListener = this._keyListener.bind(this)
    document.addEventListener('keydown', this._keyListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
  },

  data() {
    return {
      title: 'Trovan',
      env: null,
      loading: true,
      fayeUrl: `http://${location.hostname}:3001/faye`,
    };
  },

  methods: {
    onFayeConnected() {
      this.notySubscribe();
    },

    notySubscribe() {
      if (this.notySubscription != null) {
        this.notySubscription.cancel();
      }
      this.notySubscription = this.faye.subscribe('/noty', (data) => {
        this.onNoty(data);
      });
    },

    async onNoty(data) {
      if (data.message == null) {
        return;
      }

      let timeout = 3000;
      if (data.timeout != null) {
        timeout = parseInt(data.timeout)
      }

      if (data.type != null && data.type.toLowerCase() == 'error') {
        notyError(data.message, timeout);
      } else {
        notySuccess(data.message, timeout);
      }
    },

    updatePageZoom() {
      if (this.$config['PAGE_ZOOM'] != null && parseFloat(this.$config['PAGE_ZOOM']) > 1) {
        // eslint-disable-next-line
        $('html').css("zoom", this.$config['PAGE_ZOOM']);
        this.updateContentSize();
      }
    },

    updateContentSize() {
      setTimeout(() => {
        // eslint-disable-next-line
        const headerHeight = $('nav').height();
        // eslint-disable-next-line
        const footerHeight = $('footer').height();
        // eslint-disable-next-line
        const keyboardHeight = $('.virtual-keyboard-container').height() + 20 || 0;
        // eslint-disable-next-line
        const windowHeight = $('body').height();
        const contentHeight = windowHeight - (headerHeight + footerHeight + keyboardHeight);

        // console.log("headerHeight", headerHeight, footerHeight, keyboardHeight);
        // console.log("contentHeight", windowHeight, contentHeight);
        // console.log("this", this.$route);

        // eslint-disable-next-line
        $('.app-content-wrapper').height(contentHeight);
        // // eslint-disable-next-line
        // $('.app-content').height(contentHeight);
      }, 300);
    }
  },

  watch: {
    '$route.name'() {
      this.updateContentSize();
    }
  }
};
</script>

<style lang="scss">
    @import './assets/css/app.scss';
</style>
