import $ from 'jquery';
import _ from 'lodash';

export default {

  mounted() {
    this.startListenVudalKeys();
  },
  beforeDestroy() {
    this.stopListenVudalKeys();
  },
  methods: {
    startListenVudalKeys() {
        this.$eventHub.$on('keydown', (e) => {
            const activeModals = _.filter(this.$modals.modals, modal => modal.isVisible === true);
            if (activeModals != null && activeModals.length > 0) {
              if (e.key == 'ArrowRight' || e.key == 'Tab') {
                const activeModal = activeModals[0];
                this.goVudalToField(activeModal, true);    
                e.preventDefault();
              }
              if (e.key == 'ArrowLeft' || (e.key == 'Tab' && e.shiftKey)) {
                const activeModal = activeModals[0];
                this.goVudalToField(activeModal, false);    
                e.preventDefault();
              }
            }
        });
    },

    stopListenVudalKeys() {
        this.$eventHub.$off('keydown');
    },

    goVudalToField(modal, next = true) {
      const focusedElem = document.activeElement;

      if (modal != null) {
          const formFields = $(modal.$el).find(':input, .selectable-action').toArray();

          if (formFields.length > 0) {
              let currentFieldIndex = null;
              for (const fieldIndex in formFields) {
                  if (formFields[fieldIndex] == focusedElem) {
                      currentFieldIndex = parseInt(fieldIndex, 10);
                      break;
                  }
              }

              // select first field
              if (currentFieldIndex == null) {
                  currentFieldIndex = -1;
              }

              if (currentFieldIndex != null) {
                  if (next == true) {
                      if (formFields[currentFieldIndex + 1] != null) {
                          this.focusToField(formFields[currentFieldIndex + 1]);
                          return;
                      } else {
                          this.focusToField(formFields[0]);
                          return;
                      }
                  } else {
                      if (formFields[currentFieldIndex - 1] != null) {
                          this.focusToField(formFields[currentFieldIndex - 1]);
                          return;
                      } else {
                          this.focusToField(formFields[formFields.length - 1]);
                          return;
                      }
                  }
              }
          }
      }
    },
    focusToField(elem) {
      setTimeout(() => {
          $(elem).focus();
      }, 10);
    },
    isActiveModals() {
        const activeModals = _.filter(this.$modals.modals, modal => modal.isVisible === true);
        if (activeModals != null && activeModals.length > 0) {
            return true;
        }
        return false;
    },
  },
};