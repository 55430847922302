const routes = [
  {
    name: 'selectSession',
    path: '/',
    component: () => import('./pages/SelectSession'),
  },
  {
    name: 'scanCylinder',
    path: '/scan',
    component: () => import('./pages/ScanCylinder'),
  },
  {
    path: '*',
    name: 'pageNotFound',
    component: require('./pages/PageNotFound').default,
  },
];

export default routes;
