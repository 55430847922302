import fayeClient from '@trovan/vue-base/services/FayeClient';

export default {

  created() {
    this.connectToFaye();
  },

  data() {
    return {
      faye: null,
      fayeUrl: null,
      wasDisconnected: false,
    };
  },

  methods: {
    connectToFaye() {
      if (this.fayeUrl == null) {
        console.error('Scanning: cannot initialize faye, url is not defined, add fayeUrl');
        return;
      }

      this.faye = fayeClient(this.fayeUrl);
      setTimeout(() => {
        this.onFayeConnected();
      }, 100);

      // If faye server is down, indicate it
      this.faye.on('transport:down', () => {
        // If was initialized previously, do nothing
        // if (this.canScan) {
            // return;
        // }
        this.onFayeDown();
    
        this.wasDisconnected = true;
      });
    },

    onFayeConnected() {
    },
  },


};
