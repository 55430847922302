import Vue from 'vue';
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import VueResourceCaseConverter from 'vue-resource-case-converter';
import { VudalPlugin } from 'vudal';
import VueI18n from 'vue-i18n'
// import Maska from 'maska';
import $ from 'jquery';

import initRouter from './router';

import routes from './routes';

import App from './App.vue'

// Styles
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'font-awesome/css/font-awesome.min.css';

const requireAllFilters = require.context('./filters', false, /.js$/);
requireAllFilters.keys().forEach(requireAllFilters);

const requireAllImages = require.context('./assets', true, /.(png|jpg|gif|jpeg)/);
requireAllImages.keys().forEach(requireAllImages);

Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(VueI18n);
Vue.use(VueResourceCaseConverter, {
  responseUrlFilter(url) {
    return url.indexOf('api') >= 0 || url.indexOf('auth') >= 0 || url.indexOf('me') >= 0;
  },
});
Vue.use(VudalPlugin,{
  hideModalsOnDimmerClick: false,
});
// Vue.use(Maska);
window.Vue = Vue;
window.$ = $;

// Vue.http.headers.common['X-CSRF-TOKEN'] = document.querySelector('#token').getAttribute('content');

const envMetaElement = document.querySelector('#env');
Vue.env = envMetaElement !== null ? envMetaElement.getAttribute('value') : 'production';

const router = initRouter({
  routes,
});

const i18n = new VueI18n({
  locale: 'en',
  messages: {}
})

Vue.prototype.$eventHub = new Vue();
Vue.prototype.$config = null;

new Vue({
  router,
  i18n,
  render(h) {
    return h(App);
  },
}).$mount('#app');
