<template>
  <div class="title">
    {{ $t('errors.page_not_found') }}
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
  .title {
    width: 100%;
    height: 100%;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
    font-size: 72px;
    margin-top: 40px;
    font-weight: 100;
    font-family: 'Ubuntu';
  }
</style>
