import Vue from 'vue';
import _ from 'lodash/fp';

export default Vue.filter('valueOfArray', (value, array, field = 'name') => {
  if (!array || array == null) {
    return null;
  }

  return _.flow(
    _.find(item => item.id === value),
    _.get(field),
  )(array);
});
