// import Vue from 'vue';
import VueRouter from 'vue-router';

export default function (params) {
  const options = {
    hashbang: true,
    // history: true,
    // mode: 'history',
    ...params,
  };

  const router = new VueRouter(options);

  router.beforeEach((to, from, next) => {
    next();
  });


  return router;
}
